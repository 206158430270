import React, { useState , useEffect} from 'react';
import { convertImage }  from '../services/contents';
import VideoPlayerHolder from "./Styles/videoPlayerComponentStyle";
import {Icon} from 'antd';

const VideoPlayerComponent = ({ relation }) => {

  const videoSource = relation.content ? relation.content.file_thumb : relation.file_thumb;
  const thumbnailSource = convertImage(videoSource);

  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isFillScreen, setIsFillScreen] = useState(relation.fill_screen);

  useEffect(() => {
    setIsFillScreen(relation.fill_screen);
  }, [relation.fill_screen]);

  const handlePlay = () => {
    setIsVideoVisible(true);
  };

  return (
    <VideoPlayerHolder>
        {isVideoVisible ? (
          <div className="screen">
            <video
              controls
              className={isFillScreen ? "screenPreviewFilled" : "screenPreview"}
              onPlay={handlePlay}
              autoPlay
            >
              <source src={videoSource} type="video/mp4" />
            </video>
          </div>
        ) : (
          <div className="screen thumbnail-wrapper">
            <img
              src={thumbnailSource}
              alt="thumbnail"
              className={isFillScreen ? "screenPreviewFilled" : "screenPreview"}
            />
            <div className="play-button" onClick={handlePlay}>
              <Icon type="caret-right" style={{ fontSize: '24px', color: 'white' }} />
            </div>
          </div>
        )}
    </VideoPlayerHolder>
  );
};

export default VideoPlayerComponent;