import styled from "styled-components";
import Colors from "../../themes/Colors";

const CartAudienceHolder = styled.div`
  .cartAudience {
    position: fixed;
    z-index: 996;
    top: 10px;
    right: 30px;
    height: auto;
    width: 210px;
    line-height: 25px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;

    @media only screen and (max-width: 575px) {
      position: relative;
      margin-left: 10px;
      bottom: 0px;
      top: 0px;
      left: 0px;
    }

    @media only screen and (min-width: 576px) {
      top: calc(5px + 300px + 50px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(120px + 170px + 12px);
    }
  }
  .cartAudienceHidden {
    position: fixed;
    z-index: 2;
    top: calc(50% - 25px);
    right: -150px;
    height: 45px;
    width: 150px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
    @media only screen and (min-width: 768px) {
      top: calc(85px + 127px + 140px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(85px + 77px + 120px);
    }
  }

  .cartAudienceVideoUpload {
    position: fixed;
    z-index: 996;
    top: 10px;
    right: 10px;
    height: auto;
    min-width: 11%;
    line-height: 25px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    margin-bottom: 10px;
    @media only screen and (min-width: 576px) {
      top: calc(5px + 300px + 50px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(49px + 170px + 12px);
      max-width: 100%;
    }
    @media only screen and (max-width: 1400px) {
      max-width: 100%;
      .impacts-container {
        line-height: 20px;
      }
    }
    @media only screen and (max-width: 1200px) {
      position: inherit;
      width: 100%;
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }

  .impacts-container{
    display: flex;
    align-items: center;
  }
  .text-title {
    text-align: left;
    font-size: 20px;
    opacity: 0px;
    margin-bottom: 20px;
  }
  .content-card {
    display: flex;
  }
  .text-data{
    font-weight: 600;
    font-size: 18px;
  }
  .col-card{
    display: content;
    margin-right: 25px;
    margin-left: 25px;
  }
  .col-card-margin{
    margin-top: 15px;
    margin-left: 0px;
  }
  .line-divider{
    width: 90%;
    min-width: 80%;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .buttonAudienceDetails {
    background: none;
    margin-left: 10px;
    height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }
  .cartAudience {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
  }
  .cartAudienceHidden {
    transition: all 0.3s cubic-bezier(1, 0.355, 0.045, 0.645)
  }
  .cartAudienceHidden .ant-row {
    visibility: hidden;
  }
  .cartAudience .anticon.aimIcon svg, .cartAudienceVideoUpload .anticon.aimIcon svg{
    width: 19px;
    height: 19px;
  }
  .cardAudienceInfo {
    font-size: 13px;
    margin-right: 5px
  }
  .cardAudienceTypeInfo {
    font-size: 12px
  }
  .cardAudienceIconInfo {
    margin-left: 5px;
    margin-right: 10px;
  }
  .carAudienceAimIcon {
    margin-right: 7px;
    margin-left: 7px
  }
}`;

export default CartAudienceHolder;