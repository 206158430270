import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Icon, Row, Tooltip, Typography, Button} from "antd";
import CartBudgetStyleHolder from "./Styles/cartBudgetStyle";
import NumberFormat from "./NumberFormat";
import { addCustomContent } from '../redux/DisplayRedux';

const { Text, Title } = Typography;

const CartBudgetComponent = ({
  stepsCurrent,
  updateByAmountBudget,
  amountBudget,
  realAmount,
  currentCampaign,
  isPaymentStatusApproved,
  editCampaignActive,
  currency,
  customContents,
  cart,
  updateRealAmount }) => {

  const { t } = useTranslation();
  const [calculatedBudget, setCalculatedBudget] = useState(0);
  const [currentCurrency, setCurrentCurrency] = useState(currency);

  const isDifferentMount = () => {
    const amountBudgetAux = Number(amountBudget);
    if (calculatedBudget > amountBudgetAux) return true;
    if (calculatedBudget <= (amountBudgetAux * 0.90)) return true;
    return false;
  }

  useEffect(() => {
    var newAmount = 0;
    customContents.forEach((relation) => {
      let cartSelect = null;
      let cpm = 0;
      if (editCampaignActive){
        // If add new display when editing campaign => cartSelect === null
        cartSelect = currentCampaign.contents_displays.find(item => item.display_id === relation.displayId)
        cpm = !!cartSelect ? cartSelect.cpm : relation.smart_campaign_cpm;
      }

      if (!editCampaignActive || !cartSelect ) {
        cartSelect = cart.find((display) => display.id === relation.displayId)
        cpm = cartSelect.smart_campaign_cpm
      }

      if(!cartSelect) return
      newAmount = newAmount + (relation.promised_shows * (cpm / 1000));
    });

    if(( newAmount !== realAmount ) && newAmount ) {
      updateRealAmount(newAmount)
    }

    if(!newAmount)  updateRealAmount(realAmount)

  }, []);

  useEffect(() => {
    setCalculatedBudget(Math.round(realAmount * 100) / 100)
    setCurrentCurrency(editCampaignActive ? currentCampaign.currency : currency);
    isDifferentMount();
  }, [realAmount, currency]);

  return (
    <CartBudgetStyleHolder>
      <div className="cartBudget">
        <Row
          type="flex"
          justify="space-between"
        >
          <Title
            level={3}
            className="text-title"
            style={{marginLeft: '5px'}}
          >
            {t('Tight budget')}
          <Tooltip
            placement="right"
            title={t('You can configure the spots per screen and see how the adjusted budget varies depending on the impact you want.')}
          >
            <Icon type="question-circle" theme="outlined" style={{marginLeft: "5px"}} />
          </Tooltip>
          </Title>
        </Row>
        <Row>
          <div className='content-info' style={{marginTop: '10px', marginLeft: '5px', marginRight: '5px'}}>
            <Text>
              <NumberFormat
                value={calculatedBudget === 0 ? amountBudget : calculatedBudget}
                currency={currentCurrency}
              /> 
              <span style={{fontSize: '10px'}}>+ imp</span>
            </Text>
            { ( isDifferentMount() && (stepsCurrent !== 0) && !isPaymentStatusApproved ) && (
              <Tooltip
                placement="right"
                title={t('The initial amount was modified; you can redistribute the budget')}
              >
                <Icon
                  type="exclamation-circle"
                  theme="outlined"
                  style={{color: "#fadb14eb", fontSize: "18px", marginLeft: "5px"}}
                />
              </Tooltip>
            )}
          </div>
        </Row>
        <Row style={{textAlign: "center"}}>
          {((stepsCurrent !== 0) && !isPaymentStatusApproved ) && (
              <Tooltip
                className="appear-animation-without-movement"
                placement="right"
                title={t('For each screen it will equalize the investment and recalculate the spots (changes you have made to spots for each ad or screen will be erased)')}
              >
                <Button
                  className='groupFilterButton'
                  onClick={() => updateByAmountBudget(realAmount)}
                  style={{marginBottom: '5px'}}
                >
                  <span className='balanceButtonText'>
                    {t("Balance $")}
                  </span>
                </Button>
              </Tooltip>
            )}
        </Row>
      </div>
    </CartBudgetStyleHolder>
  );
}

export default CartBudgetComponent;
