import styled from "styled-components";
import Colors from "../../themes/Colors";

const CartInitialBudgetStyleHolder = styled.div`
  .cartBudget {
    position: fixed;
    z-index: 996;
    top: 0px;
    left: 12px;
    height: auto;
    background-color: ${Colors.primary};
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 5px 0px 10px 5px;
    width: 11%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;

    @media only screen and (max-width: 1200px) {
      position: absolute;
      width: 30%;
      top: 110px;
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 500px) {
      width: 40%;
      top: 110px;
      margin-bottom: 10px;
    }

    @media only screen and (min-width: 576px) {
      top: calc(5px + 300px + 50px);
    }

    @media only screen and (min-width: 1000px) {
      top: calc(5px + 145px + 50px);
      max-width: 11%;
    }
    @media only screen and (max-width: 1400px) {
      max-width: 11%;
      .impacts-container {
        line-height: 20px;
      }
    }
    @media only screen and (max-width: 1200px) {
      position: inherit;
      max-width: 100%;
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .text-title {
    font-size: 20px;
  }
  span {
    font-size: 16px;
    color: inherit;
  }

  h3 {
    color: inherit;
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
  }

  svg {
    font-size: 20px;
  }
  .titleInfo {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .infoDate {
    margin-top: 5px;
    margin-bottom: 1px;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    text-align: right;
    color: #D7EAFD;
    margin-right: 10px;
  }
  .ant-row-flex {
    align-items: baseline;
    flex-wrap: nowrap;
    gap: 8px;
  }
  .content-info {
    padding: 5px 6px 5px 6px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #3996F3;
  }
  .groupFilterButton {
    background-color: transparent;
    color: white;
    border-radius: 4px;
    padding: 4px 10px;
    height: 32px;
    border: 1px solid white;
    .balanceButtonText {
      font-size: 12px;
    }
  }
}`;

export default CartInitialBudgetStyleHolder;