import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import CampaignManagerHolder from "./Styles/campaignManagerStyle";
import {
  Collapse,
  Steps,
  Button,
  Input,
  Row,
  Col,
  Icon,
  Form,
  message,
  Modal,
  notification,
  Tooltip,
  Select,
  Switch
} from "antd";
import { withRouter } from "react-router";
import { getAspectRatio, getSimilarAspectRatio, getCpmByStatusCampaign } from "../services/display";
import { withTranslation } from 'react-i18next';
import UserRoleEnum from '../constants/UserRoleEnum';
import { hasAccessRole, validateCharLimit } from "../services/utils"
import VerifiedAgencyInfoModal from './VerifiedAgencyInfoModal';
import CartAudienceContainer from "../containers/CartAudienceContainer";
import ContentsLibraryContainer from "../containers/ContentsLibraryContainer";
import ContentsOfCampaignContainer from "../containers/ContentsOfCampaignContainer";
import CardGeneralContainer from "../containers/CardGeneralContainer";
import PaymentCampaignContainer from "../containers/PaymentCampaignContainer";
import { random, debounce } from "lodash";
import RulesContainer from "../containers/RulesContainer";
import ScreensGroupFilterContainer from "../containers/ScreensGroupFilterContainer";
import CartBudgetContainer from "../containers/CartBudgetContainer";
import CartInitialBudgetContainer from "../containers/CartInitialBudgetContainer"
import ScreensCollectionsContainer from "../containers/ScreensCollectionsContainer";
import { getRelationId } from "../services/utils";
import { findDOMNode } from 'react-dom';
import ModalInfoNewViewComponent from "./ModalInfoNewViewComponent";
import CardClientBrandContainer from "../containers/CardClientBrandContainer";
import CardDSPScreensContainer from "../containers/CardDSPScreensContainer";
import CardAdsInfoContainer from "../containers/CardAdsInfoContainer";
import CardAverageCPMContainer from "../containers/CardAverageCPMContainer";
import CardTotalSpotsContainer from "../containers/CardTotalSpotsContainer";

const { Option } = Select;
const { Step } = Steps;
const steps = [
  { title: 'Spots', content: 'defineContent'},
  { title: 'Configuration', content: 'defineDisplay'},
  { title: 'Make payment', content: 'payment'},
];

const CATEGORY_SELECT_CLASS = 'selectCategories';

const { Panel } = Collapse;
const customPanelStyle = {
  background: '#FFFFFF',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'auto',
  boxShadow: "rgba(0, 0, 0, 0.25) 1px 2px 4px 0px",
};

const customPanelStyleContent = {
  borderTopWidth: "0px",
  borderLeftWidth: "0px",
  borderRightWidth: "0px",
}

class CampaignManagerComponent extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.debouncedGetAudience = debounce(this.props.getAudience, 500);
    this.campaignNameFormRef = createRef();
    this.contentsDisplaysFormRef = createRef();
    this.contentsPanelRef = createRef();
    this.categorySelectRef = React.createRef();
  }

  state = {
    errors: {},
    excessTraditionalVideoLength: false,
    excessProgrammaticVideoLength: false,
    visibleClearModal: false,
    generalRuleActive: false,
    rules: [
      { "conditions": [] }
    ],
    arrayTime: {
      "type": "hour",
      "from": "00:00",
      "to": "23:59",
    },
    arrayDays: {
      "type": "day",
      "value": []
    },
    canva: {},
    uploadingFile: false,
    canvaModalVisible: false,
    rulesModalVisible: false,
    width: 300,
    height: 300,
    totalShows: 0,
    totalPromisedShows: 0,
    subsidizeTotalPromisedShows: 0,
    checkNullRules: false,
    subsidized_all: false,
    inputCharLimit: {
      value: 0,
    },
    areaCharLimit: {
      value: 0,
    },
    arrContentChanged: [],
    isVerifiedAgencyModalVisible: false,
    // new videoUpload
    stepsCurrent: 0,
    settingFilter: "resolution",
    consumedAmountBudget: 0,
    creditAvailableOnTransferredImpressions: [],
    viewCampaignPayment: false,
    visibleVideoModal: false,
  };

  componentWillUnmount() {
    // Clean up the campaign when it has been created.
    const {editCampaignActive, clearCampaignData , currentCampaign } = this.props;
    if( !editCampaignActive && this.state.stepsCurrent === 2 ) clearCampaignData();
    if( editCampaignActive && currentCampaign.payment_status === "approved" && this.state.stepsCurrent === 1 ) clearCampaignData();
  }

  calculatePromisedShow = (display, countRelation) => {
    const { cart, amountBudget, programmatic } = this.props;
    if (programmatic !== 'programmatic') return null;
    if (countRelation === 0) return 0;
    const result = Math.trunc(((parseFloat(amountBudget) / cart.length) / (display.smart_campaign_cpm / 1000)) / countRelation);
    return result ? result : 0;
  }

  addRelationDisplayContent = (customContentsLength, display, contentId, promised_show, index) => {
    const { subsidized_all } = this.state;
    const isDisplayInCampaign = this.props.customContents.filter(c => c.displayId === display.id);
    const subsidizeRelationsInDisplay = isDisplayInCampaign.every(c => c.subsidized_price === true);
    let relationId = display.id + '_' + (customContentsLength + random(99999999))
    const content = this.props.listOfContentsInCampaign.find(content => content.id === contentId);
    // TO DO: Modify for update campaign
    var data = {
      relationId: relationId,
      indice_relation: index,
      active: this.props.isCampaignPaused ? 0 : 1,
      displayId: display.id,
      fill_screen: display.fill_screen,
      file_thumb: content.content_file,
      isContentMixed: false,
      promised_shows_subsidized: 0,
      promised_shows: promised_show,
      id: content.id,
      length: content.length,
      content: { ...content },
      rules: content.rules && content.rules.length > 0 ? JSON.parse(content.rules) : [],
      content_version_name: content.content_version_name,
      length: content.length,
      cpm: display.cpm ? display.cpm : display.smart_campaign_cpm,
      subsidized_price: isDisplayInCampaign && isDisplayInCampaign.length > 0 ? subsidizeRelationsInDisplay : subsidized_all,
    }

    this.props.addCustomContentFromCampaign(
      data.relationId,
      data.indice_relation,
      data.active,
      data.displayId,
      data.fill_screen,
      data.isContentMixed,
      data.promised_shows,
      data.length,
      data.content,
      data.rules,
      data.subsidized_price,
      data.promised_shows_subsidized,
      content.name,
      data.cpm
    );
  };


  distributeImpressionsForActiveCampaign = (customContentFilterByDisplay, totalContents) => {
    const totalPromisedShowsInDisplay = customContentFilterByDisplay.reduce(
      (acc, content) => acc + content.promised_shows, 0
    );
    const impressionsPerScreen = totalPromisedShowsInDisplay / totalContents;
    let fullImpressions = Math.floor(impressionsPerScreen);
    let remainder = totalPromisedShowsInDisplay - (fullImpressions * totalContents);
    let customContentFilterByDisplayAux = customContentFilterByDisplay.map((content, index) => {
      const promised_shows = fullImpressions + (index < remainder ? 1 : 0);
      if (remainder) remainder--;
      return { ...content, promised_shows };
    });
    // Check if redistributed all impressions
    let newTotalPromisedShows = customContentFilterByDisplayAux.reduce(
      (acc, content) => acc + content.promised_shows, 0
    );
    newTotalPromisedShows = newTotalPromisedShows + fullImpressions
    if (newTotalPromisedShows < totalPromisedShowsInDisplay) {
      const diff = totalPromisedShowsInDisplay - newTotalPromisedShows
      fullImpressions = fullImpressions + diff
    }
    if (newTotalPromisedShows > totalPromisedShowsInDisplay) {
      const diff = newTotalPromisedShows - totalPromisedShowsInDisplay
      fullImpressions = fullImpressions - diff
    }
    return [customContentFilterByDisplayAux, fullImpressions];
  };

  // Calcule the impressions for the new relation depending of the total of contents in the display
  calculateAndApplyRelation = (
    isTheFirstApply,
    customContentFilterByDisplay,
    displayData,
    listResolution,
    index,
    foundContent,
    addNewDisplay ) => {

      const { realAmount, isPaymentStatusApproved } = this.props;
      let promised_show = 0;

      // When campaign is active
      let [customContentFilterByDisplayAux, fullImpressions] = []
      if ((!isTheFirstApply && customContentFilterByDisplay.length > 0 && !addNewDisplay) || isPaymentStatusApproved) {
        if (displayData.totalContents > 1) {
          [customContentFilterByDisplayAux, fullImpressions] = this.distributeImpressionsForActiveCampaign(customContentFilterByDisplay, displayData.totalContents, displayData.display.id);
          if (fullImpressions) {
            this.props.updatePromiseShowsInRelations(customContentFilterByDisplayAux);
          }
          promised_show = fullImpressions;
        }
      }

      if((addNewDisplay)) {
        promised_show = this.calculatePromisedShow( displayData.display, displayData.totalContents);
        const amount_aux = realAmount + (promised_show * displayData.display.smart_campaign_cpm)
        this.props.updateRealAmount(realAmount + amount_aux)
      }

      // The promise show are defined with updateByAmountBudget
      if (isTheFirstApply) {
        promised_show = 0;
      }

      let indice = listResolution.contentsDisplays.length > 1 ? index : null;
       if (promised_show > 99999999) {
        promised_show = 99999998;
       }

      this.addRelationDisplayContent(
        displayData.totalContents,
        displayData.display,
        foundContent.id,
        promised_show,
        indice
      );
  };


  getBudgetFixed ( editCampaignActive, currentCampaign, customContents) {
    let totalBudgetFixed = 0
    customContents.forEach(cc => {
      if (cc.isFixedBudget) {
        const display = this.props.cart.find(d => d.id === cc.displayId);
        const cpm = getCpmByStatusCampaign( editCampaignActive, currentCampaign, display )
        totalBudgetFixed = totalBudgetFixed + ( cc.promised_shows * (cpm / 1000))
      }
    });
    return totalBudgetFixed
  }

  updateByAmountBudget = (amountBudget) => {
    const { cart, customContents, editCampaignActive, currentCampaign } = this.props;
    if(!customContents || customContents.length < 1) return

    const fixedBudget = this.getBudgetFixed( editCampaignActive, currentCampaign, customContents)
    const totalBudget = parseFloat(amountBudget).toFixed(2) - fixedBudget;
    const countDisplayNotFixedBudget = [
      ...new Set(
        customContents
          .filter(item => !item.isFixedBudget).map(item => item.displayId)
      )
    ];
    const amountForDisplays = totalBudget / countDisplayNotFixedBudget.length;

    let totalAssignedBudget = 0;
    let customContentUpdate = [];
    let remainderCampaign = 0;

    cart.forEach(display => {
      const customContentsFiltered = customContents.filter(c => c.displayId === display.id && !c.isFixedBudget);
      let smartCampaignCpmForDisplay = 0
      const cpm = getCpmByStatusCampaign( editCampaignActive, currentCampaign, display )
      smartCampaignCpmForDisplay = cpm / 1000;
      let remainderDisplay = remainderCampaign;
      customContentsFiltered.forEach(content => {
        if (content.displayId && content.relationId) {
          const amountForRelation = (amountForDisplays / customContentsFiltered.length) + remainderDisplay;
          const originalTotalShows = smartCampaignCpmForDisplay > 0
            ? amountForRelation / smartCampaignCpmForDisplay
            : 0;
          let totalShows = Math.trunc(originalTotalShows);
          remainderDisplay = (originalTotalShows % 1) * smartCampaignCpmForDisplay;
          totalAssignedBudget += totalShows * smartCampaignCpmForDisplay;
          customContentUpdate.push({
            ...content,
            promised_shows: totalShows || 0
          });
        }
      });
      remainderCampaign = remainderDisplay || 0;
    });
    this.props.updatePromiseShowsInRelations(customContentUpdate)
  };

  // This function creates the relation between the display and the content.
  // return: contents_displays
  applyRelationOnScreens = (groupedDisplays = null, updatedListResolution = null) => {
    const { customContents, listResolutionWithContent } = this.props;
    const displayDataMap = {};
    const listResolutionWithContentAux = updatedListResolution ? updatedListResolution : listResolutionWithContent;
    const isTheFirstApply = customContents.length === 0;

    // Create a map with the display data for optimization
    listResolutionWithContentAux.forEach(lr => {
      lr.displays.forEach(display => {
        const displayId = display.id;
        if (!displayDataMap[displayId]) {
          displayDataMap[displayId] = {
            display: display,
            totalContents: 0,
          };
        }
        displayDataMap[displayId].totalContents += lr.contentsDisplays.reduce((count, item) => {
          return item.displayId === displayId ? count + 1 : count;
        }, 0);
      });
    });

    // Check if add new display
    let addNewDisplay = false;
    if (!groupedDisplays) {
      addNewDisplay = Object.values(displayDataMap).some(displayData =>
        !customContents.some(c => c.displayId === displayData.display.id)
      );
    }

    // Iterate over listResolutionWithContent to add new relations
    listResolutionWithContentAux.forEach(listResolution => {
      if (listResolution && listResolution.contentsDisplays) {
        listResolution.contentsDisplays.forEach((contentDisplay, index) => {
          const displayData = displayDataMap[contentDisplay.displayId];
          if (groupedDisplays && !groupedDisplays.find(display => display.id === displayData.display.id)) {
            return;
          }
          const foundContent = listResolution.contents.find(content => content.id === contentDisplay.contentId);
          if (!foundContent) return;
          const customContentFilterByDisplay = customContents.filter(c => (c.displayId === contentDisplay.displayId));
          const customContentFilterByContent = customContentFilterByDisplay.filter(c => c.content.id === foundContent.id);
          // If relation exist, update the content name
          if (customContentFilterByContent.length > 0) {
            if (customContentFilterByContent[0].content.name != foundContent.name) {
              customContentFilterByContent.forEach(c => {
                this.props.updateContentName(c.relationId, foundContent);
              })
            }
            return
          }
          // Update the budget when add new relations
          this.calculateAndApplyRelation(
            isTheFirstApply,
            customContentFilterByDisplay,
            displayData,
            listResolution,
            index,
            foundContent,
            addNewDisplay);
        })
      }
    });
  };

  updateCreditAvailable = (creditAvailable) => {
    const { creditAvailableOnTransferredImpressions } = this.state;
    const indiceExistente = creditAvailableOnTransferredImpressions.findIndex(item => item.company_id === creditAvailable.company_id);

    if (indiceExistente !== -1) {
      creditAvailableOnTransferredImpressions[indiceExistente] = creditAvailable;
    } else {
      creditAvailableOnTransferredImpressions.push(creditAvailable);
    }
    this.setState({ creditAvailableOnTransferredImpressions });
  };

  nextSteps = () => {
    if (!this.isSubmitOk()) { return; }
    // Loader customContents
    if (this.state.stepsCurrent == 0) {
      this.applyRelationOnScreens()
    }
    //Create campaing
    if (this.state.stepsCurrent == 1 && !this.props.editCampaignActive) {
      this.createCampaign()
    }
    const stepsCurrent = this.state.stepsCurrent + 1;
    this.setState({ stepsCurrent });
  }

  prevSteps = () => {
    const { campaign } = this.props;
    if (this.state.stepsCurrent === 0) {
      this.applyRelationOnScreens() // is necesary for save new changes in customContents
      this.props.history.push("/map");
    }
    if (this.state.stepsCurrent != 0) {
      this.checkSubsidizedPrice();
    }
    if (this.state.stepsCurrent === 2) {
      this.props.activateEditCampaign(campaign);
    }
    const stepsCurrent = this.state.stepsCurrent - 1;
    this.setState({ stepsCurrent });
  }

  hasAccessIsPauseCampaign = (allDisplaysAreOwn) => {
    const { user, editCampaignActive, currentCampaign } = this.props;
    return editCampaignActive && currentCampaign.payment_status !== 'pending'
      && this.props.currentCampaign.payment_status !== 'cancelled' && user
      && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)
  }

  handleErrorFocus = (errorKey) => {
    const refMap = {
      'campaignName': this.campaignNameFormRef,
      'contentsDisplays': this.contentsPanelRef,
      'categories': this.categorySelectRef
    };

    const ref = refMap[errorKey];
    if (ref && ref.current) {
      const domNode = findDOMNode(ref.current);
      if (domNode) {
        domNode.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'center'
        });

        if (errorKey === 'campaignName') {
          const inputElement = domNode.querySelector('input');
          if (inputElement) {
            setTimeout(() => {
              inputElement.focus();
            }, 500);
          }
        }
      }
    }
  }

  validateCategories = () => {
    if (!this.props.categoriesId) {
      this.setState({
        categoryError: this.props.t('You must select a category')
      });
      return false;
    }
    return true;
  };

  isSubmitOk() {
    const { listResolutionWithContent } = this.props;
    const { stepsCurrent } = this.state;
    const errors = {};
    if (stepsCurrent === 0) {
      if (!this.props.campaignName) {
        errors["campaignName"] = this.props.t('Can not be blank');
      }
      if (!this.props.categoriesId) {
        errors["categories"] = this.props.t('You must select a category');
        setTimeout(() => {
          if (this.categorySelectRef.current) {
            this.categorySelectRef.current.querySelector(`.${CATEGORY_SELECT_CLASS}`).scrollIntoView({  
              behavior: 'smooth', 
              block: 'center' 
            });
          }
        }, 100);
      }
      let messageErrorMatch = this.props.t('All screens must have at least some content');
      listResolutionWithContent.forEach(listResolution => {
        if (listResolution.contentsDisplays.length === 0 ||
          listResolution.contentsDisplays.contents === 0 ||
          listResolution.contentsDisplays.some(content => content.contentId === null)) {
          errors["contentsDisplays"] = messageErrorMatch;
        }
      });
      if (listResolutionWithContent.length === 0) {
        errors["contentsDisplays"] = messageErrorMatch;
      }

      const firstError = Object.keys(errors)[0];
      if (firstError) {
        this.handleErrorFocus(firstError);
      }
    }
    if (stepsCurrent === 1) {
      // Check that rules isn't null for day
      this.checkNullRules = false
      this.props.customContents.map(content => {
        if (content.rules) {
          content.rules.map(rule => {
            if (rule.conditions.find(condition => condition.type === 'day').value.length < 1) {
              errors["rules"] = "Problem with rules"
              return;
            }
          })
        }
      })
    }
    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  }

  showVideoModal = () => {
    this.setState({ visibleVideoModal: !this.state.visibleVideoModal });
  }

  createCampaign = () => {
    const {
      campaignName,
      isCampaignTest,
      date_from,
      date_to,
      client,
      brand,
      campaignDescription,
      categoriesId,
      currency,
      invoiceIssuingCountry,
      listBundle,
      dsp,
      customContents,
      programmatic,
      defaultRules,
      toleranceResolution
    } = this.props;

    if (!this.isSubmitOk()) return;



    const mainContent = customContents.length > 0 ? customContents[0].content : null;
    const contentsDisplays = customContents.map(content => ({
      display_id: content.displayId,
      content_id: content.content.id,
      subsidized_price: content.subsidized_price || false,
      promised_shows_subsidized: content.promised_shows_subsidized || 0,
      fill_screen: content.fill_screen || false,
      promised_shows: content.promised_shows && programmatic === "programmatic" ? content.promised_shows : null,
      rules: content.rules || defaultRules,
      content_name: content.content.name,
      content_version_name: content.content.content_version_name
    }));

    const campaignData = {
      name: campaignName,
      is_test: isCampaignTest ? 1 : 0,
      active: 1,
      start_date: date_from,
      end_date: date_to,
      client: client.id,
      brand: brand.id,
      description: campaignDescription,
      tolerance_resolution: toleranceResolution,
      content_category_id: categoriesId,
      currency: currency,
      invoice_issuing_country: invoiceIssuingCountry,
      bundles: listBundle,
      external_dsp_id: dsp ? dsp.id : null,
      main_content_id: mainContent.id,
      smart_campaign: programmatic === "programmatic",
      contents_displays: contentsDisplays,
      new_ssp: true
    };
    return this.props.createCampaign(campaignData);
  };
  

  editCampaign = () => {
    if (!this.isSubmitOk()) { return; }
    return this.props.editCampaign(
      this.props.currentCampaign.id,
      {
        name: this.props.campaignName,
        is_test: this.props.isCampaignTest,
        start_date: this.props.date_from,
        end_date: this.props.date_to,
        client: this.props.client.id,
        brand: this.props.brand.id,
        description: this.props.campaignDescription,
        tolerance_resolution: this.props.toleranceResolution,
        content_category_id: this.props.categoriesId,
        currency: this.props.currentCampaign.currency,
        // main_content_id: this.props.defaultContent.id,
        bundles: this.props.listBundle,
        main_content_id: this.props.customContents[0].content.id,
        smart_campaign: this.props.programmatic === "programmatic" ? true : false,
        contents_displays: this.props.customContents.map(
          (content) => ({
            display_id: content.displayId,
            active: content.active,
            content_version_name: content.content.content_version_name,
            relation_id: content.relationId.toString().search(content.displayId + "_") === 0
              ? null
              : getRelationId(content.relationId),
            content_id: content.content.id,
            fill_screen: content && content.fill_screen
              ? content.fill_screen
              : false,
            promised_shows: content.promised_shows && this.props.programmatic === "programmatic"
              ? content.promised_shows
              : null,
            rules: content && content.rules
              ? content.rules
              : this.props.defaultRules,
            subsidized_price: content.subsidized_price
              ? content.subsidized_price
              : false,
            promised_shows_subsidized: content.promised_shows_subsidized
              ? content.promised_shows_subsidized
              : 0,
            content_name: content.content.name,
          })
        ),
      }
    );
  };

  handleNameChange(e) {
    this.props.setCampaignName(e.target.value);
    this.setState({
      errors: {},
      inputCharLimit: {
        ...validateCharLimit(e.target.value.length, 40, this.props.t('You reached the character limit!')),
        value: e.target.value.length,
      },
    });
    validateCharLimit()
  }

  handleDescriptionChange(e) {
    this.props.setCampaignDescription(e.target.value);
    this.setState({
      areaCharLimit: {
        ...validateCharLimit(e.target.value.length, 200, this.props.t('You reached the character limit!')),
        value: e.target.value.length,
      },
    });
    validateCharLimit();
  }

  handleIsCampaignsTestToggle = (isCampaignTest) => {
    this.props.setIsCampaignTest(isCampaignTest);
  }

  handleIsCampaignsPausedToggle = (isCampaignPaused) => {
    this.props.setIsCampaignPaused(isCampaignPaused);
    if (this.props.editCampaignActive) this.props.setActive(isCampaignPaused);
  }

  isPaused = () => {
    if (!this.props.customContents || this.props.customContents.length === 0) return

    const isPause = this.props.customContents.every(content => content.active === 0);

    if (!isPause) return
    this.props.setIsCampaignPaused(isPause);
  }

  showClearCampaignDataModal = () => {
    this.setState({
      visibleClearModal: true,
    });
  }

  hideClearCampaignDataModal = () => {
    this.setState({
      visibleClearModal: false,
    });
  };

  checkUpdateByAmountBudget(amount) {
    let amountAux = 0
    if (amount > 0 && amount !== Infinity) {
      amountAux = amount;
    }
    if (amountAux === Infinity || amountAux > 99999999) {
      amountAux = 99999999;
    }
    this.props.updateRealAmount(amountAux)
  }

  showVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: true });
  };

  hideVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: false });
  };

  loadContentInCampaign = () => {
    const seenIds = new Set();
    const listContent = this.props.customContents.filter(content => {
      const id = content.content.id;
      if (seenIds.has(id)) return false;
      seenIds.add(id);
      return true;
    }).map(content => content.content);

    this.props.setListOfContentsInCampaign(listContent);
  };

  checkSubsidizedPrice = () => {
    const { customContents } = this.props;
    const allSubsidized = customContents.length > 1 ? customContents.every(item => item.subsidized_price === true) : false;
    this.setState({
      subsidized_all: allSubsidized
    });
  }


  componentDidMount() {
    const {
      user,
      cart,
      history,
      setShouldPromptLogin,
      reportSingleData,
      currentCampaign,
      editCampaignTotalPromisedShows,
      customContents,
      editCampaignActive,
      payments,
      programmatic } = this.props

    if (editCampaignActive && currentCampaign.payment_status === 'pending' && (!payments || (payments && payments.length > 0))) {
      steps[2] = { title: 'Campaign', content: 'payment' };
    } else {
      steps[2] = { title: 'Payment', content: 'payment' };
    }

    const params = new URLSearchParams(window.location.search);
    const campaignPayment = params.get("campaignPayment");
    if(campaignPayment) {
      this.setState({ stepsCurrent: 2, viewCampaignPayment: true });
    }

    if (!user) {
      history.push("/");
      setShouldPromptLogin(true, "/");
    } else if ( cart.length === 0 && !campaignPayment ) {
      history.push("/map");
    }

    let totalShows = 0;
    if (reportSingleData && reportSingleData.report) {
      totalShows = reportSingleData.report.reduce(
        (acc, display) => {
          return acc + display.shows;
        },
        0
      );
      this.setState({ totalShows: totalShows });
    }

    let totalPromisedShows = 0;
    let subsidizeTotalPromisedShows = 0;
    if (currentCampaign && currentCampaign.contents_displays) {
      currentCampaign && currentCampaign.contents_displays.forEach(function (content_display) {
        totalPromisedShows = totalPromisedShows + content_display.promised_shows
        subsidizeTotalPromisedShows = subsidizeTotalPromisedShows + content_display.promised_shows_subsidized
      });
    }

    this.setState({ totalPromisedShows: totalPromisedShows });
    this.setState({ subsidizeTotalPromisedShows: subsidizeTotalPromisedShows });
    editCampaignTotalPromisedShows(totalPromisedShows);

    const { from } = this.props.location.state || {};

    // Setting the relation when update campaign
    if (this.props.editCampaign && from !== 'map') {
      this.loadContentInCampaign();
      this.checkSubsidizedPrice();
    }

    if (this.props.editCampaignActive) {
      this.props.getCampaignPayments(currentCampaign.id);
    }

    if (!this.props.categories || this.props.categories.length === 0) {
      this.props.getCategories();
    }
  }

  createCampaignSuccess = placement => {
    notification.success({
      message: this.props.t('Campaign successfully created'),
      description: this.props.t('You can see it in the My campaigns section.'),
      duration: 8,
      placement
    });
  };

  editCampaignSuccess = placement => {
    notification.success({
      message: this.props.t('Campaign successfully edited'),
      description: this.props.t('You can review it from this section before generating a payment.'),
      duration: 8,
      placement
    });
  };

  // Define is necesary call getAudience
  changedDataOrRules = (nextProps) => {
    let { date_from, date_to, customContents, audience, displays } = this.props;
    if (audience.displays_with_audience && audience.displays_with_audience.length === 0) return false;
    //upload file
    if (nextProps.customContents === customContents) return false;

    //newContent
    if ((customContents && nextProps.customContents) && (customContents.length !== nextProps.customContents.length)) {
      let lastContent = nextProps.customContents[nextProps.customContents.length - 1];
      if (!lastContent) return false
      if (!displays.some(display => display.id === (lastContent.displayId))) return false
      return true;
    }

    let arrContentChanged = [];
    arrContentChanged = customContents.filter((content, key) => {
      const lengthRulesCurrent = content && content.rules && content.rules.length
      const lengthRulesNext = nextProps.customContents[key] && nextProps.customContents[key].rules && nextProps.customContents[key].rules.length
      //compare changes in promised_show/impacts
      if (nextProps.customContents[key] && (content.promised_shows !== nextProps.customContents[key].promised_shows)) return true;
      //new rules
      if (lengthRulesCurrent === 0 && lengthRulesNext === 1) return true
      //compare changes in rules
      if (lengthRulesCurrent > 0 && (nextProps.customContents[key] && nextProps.customContents[key].rules) !== content.rules) {
        //change on this screen?
        if (!displays.some(display => display.id === content.displayId)) return false
        //have audience?
        if (audience.displays_with_no_audience.some(display => display.id === content.displayId)) return false
        return true
      };
      return false;
    });

    // Save relation in state that modify for then check audience
    if (arrContentChanged.length > 0) {
      this.setState({ arrContentChanged });
      return true;
    }

    if (nextProps.date_from !== date_from) return true;
    if (nextProps.date_to !== date_to) return true;
    return false;
  }

  getAudience(props) {
    let { date_from, date_to, customContents, programmatic, dsp } = props;
    let customContents_aux = customContents
    if (this.props.programmatic === 'traditional') {
      customContents_aux = customContents.map(content => ({ ...content, promised_shows: null }));
    }
    const getAudienceData = {
      "_method": "GET",
      "start_date": date_from,
      "end_date": date_to,
      "content_displays": customContents_aux,
      "type": programmatic,
      "external_dsp_id": dsp && programmatic == "programmatic" ? props.dsp.id : null,
    }

    this.debouncedGetAudience(getAudienceData);
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    const { currentCampaign } = nextProps;

    // Update audience when customContents is updated
    if (this.changedDataOrRules(prevProps) && !nextProps.loadingAudience && !prevProps.loadingAudience) {
      this.getAudience(nextProps);
    }

    if (!prevProps.editCampaign && this.props.editCampaign) this.loadContentInCampaign();

    // This case is when the user is in the second step, dependent of the state of the campaign the user can go to the third step
    if ((prevProps.editingCampaign !== nextProps.editingCampaign) && !nextProps.editingCampaign) {
      if (nextProps.errors.length === 0) {
        if (this.state.stepsCurrent === 1) {
          if (currentCampaign.payment_status === 'pending' && (!nextProps.payments || (nextProps.payments && nextProps.payments.length === 0))) {
            this.nextSteps();
            return;
          } else {
            this.editCampaignSuccess('topRight');
            this.props.editCampaignInactive();
            nextProps.history.push(`/campaign/${nextProps.campaign.id}`);
            return;
          }
        }
      }

      if (nextProps.errors && nextProps.errors[0].contents_displays && nextProps.errors[0].contents_displays[0] === 'The contents displays field is required.') {
        message.error(this.props.t('You have not selected screens, you can go back to the map and do it from there.'));
      } else if (nextProps.errors && nextProps.errors[0].message) {
        message.error(nextProps.errors[0].message);
      } else {
        message.error(this.props.t('An error occurred, please try again'));
      }
    }

    if (nextProps.listResolutionWithContent !== prevProps.listResolutionWithContent) {
      if (prevState.errors && prevState.errors.contentsDisplays) {
        this.isSubmitOk();
      }
    }

    if (nextProps.errors && nextProps.errors.length > 0 && nextProps.errors[0] === 'Token has expired') {
      message.error(this.props.t('Token expired, please login again'), 6);
      this.props.history.push("/");
      this.props.logout();
    }

    if (nextProps.customContents !== prevProps.customContents && this.props.programmatic === 'programmatic') {
      if (nextProps.customContents && nextProps.customContents.length > 0) {
        let calculatedBudget = nextProps.customContents.reduce((acc, display) => {
          const displayAux = this.props.cart.find(d => d.id === display.displayId);
          const display_cpm = displayAux ? displayAux.smart_campaign_cpm : 0;
          return acc + (display.promised_shows * display_cpm / 1000);
        }, 0);
        this.checkUpdateByAmountBudget(calculatedBudget);
      }
      if ((prevProps.customContents.length < 1)) {
        // when create customContent (isTheFirstApply == true)
        this.updateByAmountBudget(this.props.amountBudget)
      }
    }

    this.isPaused();
  }
  

  createCanvaDesign = () => {
    this.handleCanvaClose();
    this.props.canva.createDesign({
      design: {
        type: 'Poster',
        dimensions: {
          width: this.state.width ? this.state.width : 300,
          height: this.state.height ? this.state.height : 300,
          units: 'px',
        },
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        // Triggered when design is published to an image.
        // Save the image to your server as the exportUrl will expire shortly.
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", exportUrl);
        xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
        xhr.onload = () => {
          blob = xhr.response;//xhr.response is now a blob object
          setTimeout(() => {
            blob.lastModifiedDate = new Date();
            blob.name = 'Diseño en canva';
            blob.canva_id = designId;
            blob.file = blob;
            this.setState({
              uploadingFile: true,
            });
            this.props.uploadFileRequest(blob)
          }, 4000);
        }

        xhr.send();
      },
      onDesignClose: () => {
        // Triggered when editor is closed.
        console.log('hacer algo al cerrar');
      },
    });
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  handleRulesChange = (value) => {
    const { rulesModalVisible } = this.state;
    this.setState({
      rulesModalVisible: !rulesModalVisible
    });
  };

  handleCanvaClose = e => {
    this.setState({
      canvaModalVisible: false
    });
  };

  showCanvaModal = () => {
    this.setState({
      canvaModalVisible: true
    });
  };

  changeSubsidizedPrice = (checked) => {
    this.setState({
      subsidized_all: checked
    });
    this.props.updateSubsidizedPrice(
      null,
      checked,
      'multiple'
    )
  }
  changeFilter = (value) => {
    this.setState({
      settingFilter: value
    });
  }

  changeCategory = (value) => {
    this.props.setCategoriesId(value);
  }


  render() {
    const { errors, inputCharLimit, stepsCurrent, areaCharLimit } = this.state;
    const { t, user, i18n, currentCampaign, customContents, editCampaignActive, editingCampaign, programmatic, isPaymentStatusApproved } = this.props;

    const allDisplaysAreOwn = this.props.cart && this.props.cart.every(display => {
      const isOwnDisplay = (display.company_id ? display.company_id : display.company.id) == user.company_id;
      const displayHoldingId = display.holding_id ? display.holding_id : display.company.holding_id;
      const isHoldingDisplay = user.roles.includes('holding-member') && user.holding && user.holding.id === displayHoldingId;
      return isOwnDisplay || isHoldingDisplay;
    });
    const displaysByAspectRatio = [];
    this.state.excessTraditionalVideoLength = false;
    this.state.excessProgrammaticVideoLength = false;

    this.props.cart.forEach(display => {
      const current = displaysByAspectRatio.find(
        d => d.typeSize === getSimilarAspectRatio(display.resolution_width, display.resolution_height, this.props.t)
      );
      if (programmatic === 'traditional' && this.props.defaultContent.type === 'video' && Math.floor(this.props.defaultContent.length / 1000) * 1000 > display.slot_length) {
        this.state.excessTraditionalVideoLength = true;
      }
      if (programmatic === 'programmatic' && this.props.defaultContent.type === 'video' && this.props.defaultContent.length > 11000) {
        this.state.excessProgrammaticVideoLength = true
      }
      if (!current) {
        displaysByAspectRatio.push({
          aspectRatio: getAspectRatio(display.resolution_width, display.resolution_height),
          typeSize: getSimilarAspectRatio(display.resolution_width, display.resolution_height, this.props.t),
          displays: [display]
        });
      } else {
        current.displays.push(display);
      }
    });

    const { TextArea } = Input;
    return (
      <CampaignManagerHolder>
        <Row type="flex" justify="center" align="middle">
          <Col
            className="videoUploadContainer"
            xs={24}
            sm={24}
            md={22}
            lg={22}
            xl={18}
          >
            <Steps current={stepsCurrent} className="stepsContent">
              {steps.map(item => (
                <Step key={item.title} title={t(item.title)} />
              ))}
            </Steps>
            {/* Float component */}
            {stepsCurrent === 1 && (
              editCampaignActive ? (
                <Button
                  className={`continueButtons ${editingCampaign ? 'continueButtonsEditCreate' : ''}`}
                  onClick={this.editCampaign}
                  loading={editingCampaign}>
                  <span style={{ textTransform: "capitalize" }}>
                    {t("Edit")}{" "}
                  </span>
                  {!editingCampaign && (<Icon type="arrow-right" />)}
                </Button>
              ) : (
                <Button
                  className="continueButtons createButton"
                  onClick={this.nextSteps}>
                  <span style={{ textTransform: "capitalize" }}>
                    {t("Create campaign")}
                  </span>
                  <Icon className="createIcon" type="arrow-right" />
                </Button>
              )
            )}
            {stepsCurrent === 0 && (
              <Button className="continueButtons" onClick={this.nextSteps}>
                <span style={{ textTransform: "capitalize" }}>
                  {t("continue")}{" "}
                </span>
                <Icon type="arrow-right" />
              </Button>)
            }
            { this.state.viewCampaignPayment ? (
              <Button
                className="goBackButtons" 
                onClick={() => this.props.history.push(`/map`)}
              >
                <Icon
                  style={{ fontSize: "18px"}}
                  type="left"
                />{" "}
                {t('Go to map')}
              </Button>
            ) :
              (stepsCurrent === 0 ? (
                <>
                  {(!editCampaignActive || (editCampaignActive && currentCampaign.payment_status !== "approved")) && (
                    <Button className="goBackButtons" onClick={this.prevSteps}>
                      {(!editCampaignActive ||
                        ["pending", "cancelled"].includes(currentCampaign.payment_status)) && (
                          <>
                            <Icon type="arrow-left" />
                            <span style={{ textTransform: "capitalize" }}></span>
                            {currentCampaign && ["pending", "cancelled"].includes(currentCampaign.payment_status) && editCampaignActive
                              ? t("Edit")
                              : t("Map")}
                          </>
                        )}
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  className="goBackButtons"
                  disabled={editingCampaign}
                  onClick={this.prevSteps}
                >
                  <Icon type="arrow-left" />
                  <span style={{ textTransform: "capitalize" }}>{t("Go back")}{" "}</span>
                </Button>
              ))
            }
            { stepsCurrent === 0 && (
              <>
                <Button className="guide-button" onClick={this.showVideoModal}>
                  {t("View guide")}
                </Button>
                <ModalInfoNewViewComponent
                  onCancel={this.showVideoModal}
                  visible={this.state.visibleVideoModal}
                  showMainModal={false}
                />
              </>
            )}

            { /* ****** Floating ******/}
            {(stepsCurrent != 2) && (
              <>
                {programmatic === "programmatic" && (
                  <>
                    <CardAverageCPMContainer stepsCurrent={stepsCurrent} />
                  </>)}
                <CartInitialBudgetContainer
                  stepsCurrent={stepsCurrent}
                  updateByAmountBudget={this.updateByAmountBudget}
                  cart={this.props.cart}
                  customContents={this.props.customContents}
                  updateRealAmount={this.props.updateRealAmount}
                />
              </>
            )}
            {
              stepsCurrent === 0 && 
                <CardAdsInfoContainer campaign={programmatic} />
            }
            {stepsCurrent === 1 && (
              <CartBudgetContainer
                stepsCurrent={stepsCurrent}
                updateByAmountBudget={this.updateByAmountBudget}
                cart={this.props.cart}
                customContents={this.props.customContents}
                updateRealAmount={this.props.updateRealAmount}
              />
            )}
            { (stepsCurrent === 1 && programmatic === "programmatic") && (
              <CardTotalSpotsContainer stepsCurrent={stepsCurrent} customContents={this.props.customContents}/>
            )}
            {stepsCurrent !== 2 && (
              <>
                <CardGeneralContainer stepsCurrent={stepsCurrent} />
                <CardClientBrandContainer stepsCurrent={stepsCurrent} />
                <CardDSPScreensContainer stepsCurrent={stepsCurrent} />
              </>
            )}
            { /* ****** Define content ******/}
            {stepsCurrent === 0 && (
              <Collapse
              defaultActiveKey={['1', '2','3']} 
              style={customPanelStyleContent}>
                <Panel header={t("Your campaign")} key="1" style={customPanelStyle}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <p style={{fontSize: '13px'}}>
                        {t('Give your campaign a name so you can identify it')}
                      </p>
                      <Form.Item
                        validateStatus={!!errors["campaignName"] ? "error" : (inputCharLimit.validateStatus ? "warning" : null)}
                        help={errors["campaignName"] || inputCharLimit.errorMsg}
                        ref={this.campaignNameFormRef}
                      >
                        <Input
                          autoFocus
                          placeholder={t('Campaign name')}
                          value={this.props.campaignName}
                          onChange={this.handleNameChange.bind(this)}
                          maxLength={40}
                          autoComplete="off"
                        />
                      </Form.Item>
                      <div className="contentSettingCampaign">
                        
                        {this.hasAccessIsPauseCampaign(allDisplaysAreOwn) && (
                          <Form.Item>
                            <span style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              {t('Pause campaign')}
                              &emsp;
                              <Switch
                                onChange={this.handleIsCampaignsPausedToggle}
                                checked={this.props.isCampaignPaused}
                                size="small"
                              />
                            </span>
                          </Form.Item>
                        )}

                        {(!this.props.editCampaignActive) && (user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)) && (
                          <Form.Item>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '8px', maxWidth: '300px'}}>
                                <span style={{display: 'flex', alignItems: 'center'}}>
                                  {t('Subsidize price of campaign')}
                                  &emsp;
                                  <Switch
                                    checked={this.state.subsidized_all}
                                    onClick={(checked) => this.changeSubsidizedPrice(checked)}
                                    size="small"
                                  />
                                  <Tooltip placement="bottomLeft" title={t('By enabling this option, the campaign is subsidize, your price will be zero')}>
                                    <Icon
                                      style={{ fontSize: "17px", marginLeft: "10px", color: "#f7d455" }}
                                      type="warning"
                                    />
                                  </Tooltip>
                                </span>
                              {this.state.subsidized_all && (
                                <span className="ant-tag ant-tag-orange" style={{ width: 'fit-content' }}>
                                  {t('Campaign subsidized')}
                                </span>
                              )}
                            </div>
                          </Form.Item>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <p style={{fontSize: '13px'}}>{t("Categories")}</p>
                      <Form.Item 
                        validateStatus={!!errors["categories"] ? "error" : null}
                        help={errors["categories"]}
                      >
                        <div ref={this.categorySelectRef}>
                          <Select
                            defaultValue={null}
                            showSearch
                            placeholder={t("Select a category")}
                            className={CATEGORY_SELECT_CLASS}
                            value={this.props.categoriesId}
                            onChange={this.changeCategory}
                            style={{ width: "100%" }}
                            filterOption={(input, option) =>
                              (i18n.language === "en" 
                                ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                : option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                            }
                          >
                            <Option key="placeholder" value={null} style={{ color: 'gray' }}>
                              {t("None")}
                            </Option>
                            {this.props.categories.map(category => (
                              <Option key={category.id} value={category.id}>
                                {i18n.language === "en" ? category.name_en : category.name_es}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </Form.Item>
                      {this.props.isSuperUser && (
                      <Form.Item style={{display: 'flex', flexDirection: 'column'}}>
                        <span>
                          {t('Is it a test campaign?')}
                          &emsp;
                          <Switch
                            onChange={this.handleIsCampaignsTestToggle.bind(this)}
                            checked={this.props.isCampaignTest}
                            size="small"
                            disabled={(currentCampaign && !currentCampaign.smart_campaign) && this.props.editCampaignActive}
                          />
                        </span>
                      </Form.Item>
                    )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <p style={{fontSize: '13px'}}>
                        {t('Campaign objective')}
                      </p>
                      <Form.Item
                        validateStatus={areaCharLimit.validateStatus && "warning"}
                        help={errors["campaignDescription"] || areaCharLimit.errorMsg}
                      >
                        <TextArea
                          placeholder={t("Briefly describe the purpose and goals of your campaign.")}
                          onChange={this.handleDescriptionChange.bind(this)}
                          value={this.props.campaignDescription}
                          autoSize={{ minRows: 1, maxRows: 6 }}
                          maxLength={200}
                          className="textArea"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel header={t("Contents library")} key="2" style={customPanelStyle}>
                  <ContentsLibraryContainer />
                </Panel>
                <Panel
                  header={
                    <div>
                      {t("Contents for the campaign")}
                      {errors["contentsDisplays"] && (
                        <Tooltip placement="bottomLeft" title={errors["contentsDisplays"]}>
                          <Icon style={{ marginLeft: "5px", fontSize: '16px', color: "#f5222d" }} type="warning" />
                        </Tooltip>
                      )}
                    </div>
                  }
                  key="3"
                  ref={this.contentsPanelRef}
                  style={customPanelStyle}
                >
                  <ContentsOfCampaignContainer
                    isPaymentStatusApproved={isPaymentStatusApproved}
                    errors={errors}
                  />
                </Panel>
              </Collapse>)
            }
            { /* ****** Define display******/}
            {stepsCurrent === 1 && (
              <>
                <Row type="flex" className="campaingInfoCard customPanelStyleDisplays">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{display: "flex", justifyContent: "space-between"}}>
                    <div className="ant-collapse-header" role="button" aria-expanded="true">
                      {t('Group screens')}
                    </div>
                    <div style={{ textAlign: "left", marginTop: "25px", marginBottom: "15px"}}>
                      <Tooltip placement="bottomLeft" title={t('Set up rules in campaign')}>
                        <Button onClick={() => this.handleRulesChange(true)} >
                          {t("Create rules")}
                          <Icon type="control" />
                        </Button>
                      </Tooltip>
                    </div>
                    <Modal
                      title={t("Rules in campaign")}
                      visible={this.state.rulesModalVisible}
                      footer={[
                        <Button key="ok" type="primary" onClick={() => this.handleRulesChange(false)}>
                          {t("Close")}
                        </Button>,
                      ]}
                      onCancel={() => this.handleRulesChange(false)}
                      width={800}
                    >
                      <RulesContainer
                        typeModal="campaign"
                        typeName={ currentCampaign.name }
                        listContentDisplay={ customContents }
                      />
                    </Modal>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="contentFilter">
                    <ScreensGroupFilterContainer />
                  </Col>
                </Row>
                <ScreensCollectionsContainer
                  applyRelationOnScreens={this.applyRelationOnScreens}
                  allDisplaysAreOwn={allDisplaysAreOwn}
                  creditAvailableOnTransferredImpressions={this.state.creditAvailableOnTransferredImpressions}
                  updateCreditAvailable={this.updateCreditAvailable} />
              </>
            )}
            {stepsCurrent === 2 && (
              <PaymentCampaignContainer />
            )}
          </Col>
        </Row>
        <VerifiedAgencyInfoModal isVisible={this.state.isVerifiedAgencyModalVisible} hide={this.hideVerifiedAgencyModal} message={t("Company is not verified and has more than $5000 USD in campaigns this month")}></VerifiedAgencyInfoModal>
      </CampaignManagerHolder>
    );
  }
}

const Extended = withTranslation()(CampaignManagerComponent);
Extended.static = CampaignManagerComponent.static;

export default withRouter(
  Form.create({ name: "CampaignManagerComponent" })(Extended)
);
CampaignManagerComponent.contextTypes = {
  router: PropTypes.object
};
