import styled from "styled-components";
import Colors from "../../themes/Colors";



const CardTotalSpotsHolder = styled.div`
  .cardGeneral {
    position: fixed;
    z-index: 996;
    top: 550px;
    right: 10px;
    height: auto;
    background-color: ${Colors.primary};
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 10px;
    width: 11%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    max-width: 11% !important;

    @media only screen and (min-width: 1000px) {
      width: 30%;
      max-width: 30%;
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 1400px) {
      width: 30%;
      max-width: 30%;
      margin-bottom: 10px;
      .impacts-container {
        line-height: 20px;
      }
    }
  }
  .infoTotalSpots {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .totalSpotsContainer {
    background-color: white;
    border-radius: 8px;
    padding: 3px;
    margin-right: 5px;
   }
   .totalSpots {
    margin: 0;
    color: ${Colors.primary};
    font-weight: 500;
    text-align: center;
   }
`;

export default CardTotalSpotsHolder;