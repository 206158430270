
import React from 'react';
import { Row, Col } from "antd";
import { useTranslation } from 'react-i18next';
import CardAdsInfoHolder from './Styles/cardAdsInfoStyle';


const CardAdsInfoComponent = (props) => {
  const { t } = useTranslation();
  const { programmatic, listOfContentsInCampaign, listResolutionWithContent } = props;


  const totalContents = listResolutionWithContent.reduce((total, item) => {
    return total + (item.contents.length || 0);
  }, 0);

  const uniqueVersionsCount = Array.from(new Set(
    listResolutionWithContent.reduce((versions, item) => {
      item.contents.forEach(content => {
        if (content.content_version_name) {
          versions.push(content.content_version_name);
        }
      });
      return versions;
    }, [])
  )).length;

  return (
    <CardAdsInfoHolder programmatic={programmatic}>
      <div className="cardGeneral">
        <Row justify="center">
          <Col>
            <div>
              <div className='infoDate'>{t("Versions")}</div>
              <div className='brandClientNameContainer'>
                <p className='brandClientName'>{uniqueVersionsCount}</p>
              </div>
              <div className='infoDate'>{t("Selected ads")}</div>
              <div className='brandClientNameContainer'>
                <p className='brandClientName'>{listOfContentsInCampaign.length}</p>
              </div>
              <div className='infoDate'>{t("Used ads")}</div>
              <div className='brandClientNameContainer'>
                <p className='brandClientName'>{totalContents}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </CardAdsInfoHolder>
  );
};

export default CardAdsInfoComponent;